import customFetch from '@/libs/customFetch';
import permissions from '@/router/route-permissions';
import {defineStore} from 'pinia';
import {computed, reactive, ref} from 'vue';
import {useAlertaStore} from '@/store/modules/alertas';
import menuPerfis from '@/store/modules/perfis/menuPerfis';
import router from '@/router';
import {useRecuperaEmail} from "@/store/modules/recaptcha";
import i18n from '@/plugins/i18n';

export const useAdminStore = defineStore('admin', () => {
    const usuario = ref({});
    const usuarioLogado = computed(() => usuario.value);
    const usuarioTemPermissao = computed(
        () => (rota) => usuario.value && permissions[rota]?.includes(usuario.value.role)
    );

    async function login(login, password) {
        const {sucesso} = useAlertaStore();
        const res = await customFetch('/user/login', {
            method: 'POST',
            mode: 'cors',
            body: {login, password},
        });

        gravaUsuario(await res.json());
        sucesso(i18n.t('form.alerts.user.login'));
    }

    async function loginRecaptcha(usuario, senha) {
        const {sucesso} = useAlertaStore();
        let result = await customFetch('/user/login', {
            method: 'POST',
            mode: 'cors',
            headers: {'Content-type': 'application/json'},
            body: {
                login: usuario,
                password: senha,
                hashcaptcha: recaptcha.hashcaptcha,
                urlEvento: window.location.origin
            }
        });
        let response = await result.json();

        gravaUsuario(response);
        sucesso(i18n.t('form.alerts.user.login'));

        return response;
    }

    function gravaUsuario(logado) {
        usuario.value = logado;
        localStorage.setItem('token', logado.token);
        localStorage.setItem('usuario', JSON.stringify(logado));
    }

    function logout() {
        usuario.value = {};
        localStorage.removeItem('token');
        localStorage.removeItem('usuario');
        const recaptcha = useRecuperaEmail();
        recaptcha.recaptcha.hashcaptcha = null;
    }

    function checkIsLogado(callbackSucesso = () => {
    }, callbackErro = () => {
    }) {
        const logado = localStorage.getItem('usuario');

        if (logado) {
            gravaUsuario(JSON.parse(logado));
            if (logado.role !== 'consumidor') {
                return callbackSucesso();
            }

            return callbackErro();
        }

        return callbackErro();
    }

    function userLogado() {
        if (usuario.value.role) {
            return usuario.value.role.charAt(0).toUpperCase() + usuario.value.role.slice(1);
        }
    }

    async function getConfig() {
        let result = await customFetch('/user/config', {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-type': 'application/json',
            },
        });
        let jsonResponse = await result.json();
        return jsonResponse;
    }

    function checkToken() {
        let tokenLocal = localStorage.getItem('token') !== null ? localStorage.getItem('token') : undefined;
        if (!tokenLocal) {
            router.push('/')
        } else {
            this.allowDataLoad = true;
        }
    }

    async function alterarSenhaAdmin({senha_atual, senha_nova}) {
        try {
            return customFetch("/user/alterar-senha", {
                    method: "PUT",
                    mode: "cors",
                    headers: {"Content-type": "application/json"},
                    body: {
                        senha_atual,
                        senha_nova,
                    },
                },
                err => useAlertaStore().erro(i18n.t(err.response.data.tag) || err.response.data.message)
            )
        } catch (e) {
            useAlertaStore().erro(i18n.t(e.tag || e))
        }

    }

    return {
        usuario,
        usuarioTemPermissao,
        usuarioLogado,
        login,
        loginRecaptcha,
        logout,
        checkIsLogado,
        userLogado,
        getConfig,
        checkToken,
        alterarSenhaAdmin,
    };
});
