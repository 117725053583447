const pt = {
  mobileBar: {
    events: "Eventos",
    event: "Evento",
    newEvent: "Novo evento",
    tickets: "Ingressos",
    dashboard: "Dashboard",
    cards: "Cartões",
    cardList: "Lista de cartões",
    productList: "Lista de produtos",
    devices: "Dispositivos",
    equipmentByEvent: "Equipamentos por Evento",
    userGroups: "Grupos de usuários",
    reports: "Relatórios",
    reportsList: {
      salesByProduct: "Venda por produto",
      salesByOperation: "Venda por operação",
      salesByPaymentMethod: "Venda por forma de pag.",
      salesByUser: "Venda por usuário",
      detailedSales: "Venda detalhada",
      salesByCashRegister: "Venda por caixa",
      salesByClient: "Venda por cliente",
      financial: "Financeiro",
      courtesies: "Cortesias",
      reprints: "Reimpressões",
      refunds: "Estornos",
      balanceRefund: "Devolução saldo",
      onlineRecharge: "Recargas online",
      receivables: "Recebíveis",
      cashOutAndSupply: "Sangria e Suprimento",
    },
    reportsTitle: {
      salesByProduct: "Relatório de vendas por produto",
      salesByOperation: "Relatório de vendas por operação",
      salesByPaymentMethod: "Relatório de vendas por forma de pagamento",
      salesByUser: "Relatório de vendas por usuário ",
      detailedSales: "Relatório de venda detalhada ",
      salesByCashRegister: "Relatório de vendas por caixa",
      salesByClient: "Relatório de vendas por cliente ",
      financial: "Relatório financeiro",
      courtesies: "Relatório de cortesias",
      reprints: "Relatório de reimpressões",
      refunds: "Relatório de estornos",
      balanceRefund: "Relatório de devolução de saldo",
      onlineRecharge: "Relatório de recargas online",
      receivables: "Relatório de recebíveis",
      cashOutAndSupply: "Relatório de Sangria e Suprimento",
      listOfEquipmentByEvent: "Lista de Equipamentos por Evento",
    },
    products: "Produtos",
    appointments: "Agendamentos",
    categories: "Categorias",
    operations: "Operações",
    operationsList: "Lista de operações",
    users: "Usuários",
    userList: "Lista de usuários",
    deviceStatus: "Status dos dispositivos",
    digitalWallet: "Carteira Digital",
    elevenDash: "Painel ElevenCash",
    statement: "Extrato",
    personalData: "Dados Pessoais",
    changePassword: "Alterar Senha",
    balance: "Saldo",
    new: "NOVO",
  },
  status: {
    active: "Ativo",
    scheduled: "Agendado",
    closed: "Encerrado",
    all: "Todos",
    total: "Total",
    selected: "Selecionados",
    available: "Disponíveis",
  },
  filter: {
    title: "Filtros",
    rangeStart: "Início do Intervalo",
    rangeEnd: "Fim do Intervalo",
    applyFilter: "Aplicar Filtros",
    document: "Documento",
    cardCode: "Código do cartão",
    customer: "Cliente",
    startDate: "Data inicial",
    endDate: "Data final",
    user: "Usuário",
    users: "Usuários",
    type: "Tipo",
    soldCourtesy: "Vendido/cortesia",
    equipment: "Equipamento",
    operation: "Operação",
    operations: "Operações",
    paymentStatus: "Status de pagamento",
    transactionStatus: "Status de transação",
    paymentMethods: "Formas de pagamento",
    name: "Nome",
    telephone: "Telefone",
    email: "E-mail",
    dateOfBirth: "Data de nascimento",
    value: "Valor",
    acquirer: "Adquirente",
  },
  form: {
    required: "Obrigatório",
    login: "Entrar",
    signup: "Cadastrar",
    cancel: "Cancelar",
    sendEmail: "Enviar email",
    create: "Criar",
    recoverPassword: "Recuperar Senha",
    forgotPassword: "Esqueci minha senha",
    newRegistration: "Novo Cadastro",
    newUser: "Novo Usuário",
    editUser: "Editar Usuário",
    newDevice: "Novo Dispositivo",
    editDevice: "Editar Dispositivo",
    import: "Importar",
    importDevices: "Importar Dispositivos",
    importCSV: "Importar CSV",
    selectCSVFile: "Selecione um arquivo CSV",
    downloadCSVmodel: "Baixar modelo CSV",
    rules: {
      required: "Campo obrigatório.",
      invalid: "Campo inválido",
      email: "E-mail inválido.",
      upperLetters: "Utilize letras maiúsculas",
      lowerLetters: "Utilize letras minúsculas",
      numbers: "Utilize números",
      specialCharacters: "Utilize  caracteres especiais",
      minimum: "Mínimo",
      maximum: "Máximo",
      characters: "caracteres",
      passwordsDontMatch: "Senhas não coincidem",
      requiredDocument: "Documento obrigatório",
      invalidDocument: "Documento inválido",
      startRefundDateRequired: "Data de início do reembolso obrigatória",
      invalidRefundPeriod: "Período de reembolso inválido",
      endRefundDateRequired: "Data de término do reembolso obrigatória"
    },
    info: {
      login: "Por favor, faça o login",
    },
    alerts: {
      user: {
        logoff: "Usuário deslogado com sucesso!",
        login: "Usuário logado com sucesso!",
        signup: "Cadastro realizado com sucesso",
      },
      field: "Preencha o campo obrigatório.",
      fields: "Preencha os campos obrigatórios.",
      recaptcha: "Preencha o reCAPTCHA.",
      dataChanged: "Dados alterados com sucesso!",
      passwordChanged: "Senha alterada com sucesso!",
      expiredSession: "Sessão expirada, faça login novamente para continuar!",
      categoryRegistered: "Categoria cadastrada com sucesso",
      categoryUpdated: "Categoria atualizada com sucesso",
      productRegistered: "Produto cadastrado com sucesso",
      productUpdated: "Produto editado com sucesso",
    },
    label: {
      user: "Usuário",
      password: "Senha",
      fullName: "Nome completo",
      phone: "Celular",
      telephone: "Telefone",
      email: "E-mail",
      currentPassword: "Senha Atual",
      newPassword: "Nova Senha",
      canRefund: "Permite reembolso",
      confirmNewPassword: "Confirmar Nova Senha",
      serialNumber: "Número de série",
      identifier: "Identificador",
      description: "Descrição",
      deviceDescription: "Descrição do dispositivo",
      deviceType: "Tipo de dispositivo",
      tefTerminal: "Terminal TEF",
      event: "Evento",
      eventName: "Nome do Evento",
      productName: "Nome do Produto",
      selectRepresentative: "Selecione o representante",
      eventStartDate: "Data inicio do evento",
      eventEndDate: "Data fim do evento",
      eventImage: "Imagem do evento",
      operationImage: "Imagem da operação",
      operationInfo: "Informações da operação",
      groupInfo: "Informações do grupo",
      createOperationsWithPrefix: "Crie uma ou mais operações com um mesmo prefixo.",
      createAdminOperation: "Criar administrador e atendente padrões em todas as operações.",
      quantity: "Quantidade",
      viewOnly: "Somente visualização",
      canAccessReports: "Acessa relatórios da POS",
      printMerchantReceipt: "Imprimir comprovante de pagamento",
      printOperatorReceipt: "Impressão de via do operador",
      printUserReceipt: "Impressão de via do cliente",
      canActivateCard: "Ativar",
      canSellRecharge: "Recarregar",
      canConsumeRecharge: "Venda Cashless",
      canSellOnlineRecharge: "Recarga online",
      canCashUpAndCashDown: "Sangria & Suprimento", 
      searchOnlineRechargeOnCardAcquistion: "Consulta de Recargas Online na Ativação", 
      searchOnlineRechargeOnSale: "Consulta de Recargas Online na Venda",
      canRefundBalance: "Reembolso de saldo",
      canReplaceCard: "Substituir cartão",
      canTransferBalance: "Transferir saldo",
      canDisplayBalanceAndStatement: "Saldo e extrato",
      requiresDocument: "Solicitar documento",
      cashlessPrintType: "Tipo de impressão",
      cashlessPrintCopies: "Quantidade de vias Cashless",
      requiresContactInfo: "Solicitar Contato",
      canSellTicket: "Vender Ficha",
      canConsumeTicket: "Queimar Ficha",
      canAutoBurnTicket: "Queima de Ficha Automática",
      canReturnTicket: "Devolver Ficha",
      ticketPrintType: "Tipo da Impressão",
      ticketCopyQuantity: "Quantidade de Vias",
      print: "Imprimir",
      doNotPrint: "Não imprimir",
      toAsk: "Perguntar",
      profile: "Perfil",
      masterCard: "Cartão mestre",
      masterPIN: "PIN mestre",
      integratorID: "Integrador ID",
      integratorIP: "Integrador IP",
      tokenIntegration: "Token Integração",
      category: "Categoria",
      categoryName: "Nome da categoria",
      ageRating: "Classificação etária",
      displayOrder: "Ordem de exibição",
      icon: "Ícone",
      insertImage: "Inserir Imagem",
      consumption: "Consumo",
      dynamicValue: "Valor dinâmico",
      useObservation: "Utilizar observação",
      observationTitle: "Título da observação",
      taxIntegrationCode: "Código Integração Fiscal",
      cardOrBandID: "ID do Cartão ou Pulseira",
      userSettings: "Configurações do Usuário",
      operationSettings: "Configurações da Operação",
      eventSettings: "Configurações do evento",
      eventUsers: "Usuários do evento",
      financialInformation: "Informações financeiras",
      changeImage: "Alterar imagem",
      activationValue: "Valor da ativação",
      equipmentNumber: "Número de equipamentos",
      occupiedLicenses: "Licenças Ocupadas",
      eventKey: "Chave do evento",
      useEncryption: "Usar criptografia",
      refundStartDate: "Data inicial de reembolso",
      refundEndDate: "Data final de reembolso",
      onlineRefundFee: "Tarifa de reembolso online",
      customOnlineMenuURL: "URL personalizada do cardápio online",
      eventRepresentative: "Representante do evento",
      noRegisteredRepresentatives: "Sem representantes cadastrados",
      eventProducers: "Produtores do evento",
      noRegisteredProducers: "Sem produtores cadastrados",
      techniciansList: "Lista de técnicos",
      companyData: "Dados da empresa",
      individualPerson: "Pessoa Física",
      legalEntity: "Pessoa Jurídica",
      corporateName: "Razão social",
      postalCode: "CEP",
      address: "Endereço",
      fees: "Taxas",
      fee: "Taxa",
      transferRule: "Regra de repasse",
      revenuePercentage: "Percentual do Faturamento",
      bankDetails: "Dados Bancários",
      bank: "Banco",
      agency: "Agência",
      account: "Conta",
      bankDetailsValidation: "Dados bancários devem ter o mesmo nome/razão social e CPF/CNPJ que o cadastro acima",
      equipmentValue: "Valor de equipamentos",
      posQuantity: "Quantidade POS",
      posValue: "Valor por POS",
      totemQuantity: "Quantidade Totem",
      totemValue: "Valor por Totem",
      totalEquipmentValue: "Valor Total de Equipamentos",
      serviceValue: "Valor de serviços",
      freights: "Fretes",
      technicianQuantity: "Quantidade de técnico",
      technicianValue: "Valor por Técnico",
      totalTechnicianValue: "Valor Total de Técnicos",
      cardBraceletValue: "Valor de Cartões/Pulseiras",
      cardBraceletQuantity: "Quantidade de Cartões/Pulseiras",
      cardBraceletUnitValue: "Valor por Cartão/Pulseira",
      totalCardBraceletValue: "Valor Total de Cartões/Pulseiras",
      chargesValue: "Valor de cobranças",
      totalValueToCharge: "Valor total à cobrar",
      eventFrequency: "Qual a frequência do seu evento?",
      singleDate: "Data única",
      recurringEventOrBusiness: "Evento ou negócio recorrente",
      useDigitalWallet: "Utiliza a Carteira Digital?",
      paymentType: "Tipo de Pagamento",
      automaticPaymentToProducer: "Pagamento automático ao produtor",
      thirdPartyPayment: "Pagamento para terceiros",
      notes: "Observações",
      fiscalEnvironment: "Ambiente Fiscal",
      maximumDiscountPOS: "Valor máximo de desconto na POS",
      serviceFee: "Taxa de serviço",
      defaultPassword: "Senha Padrão",
      withAccess: "Com acesso ao portal",
      withoutAccess: "Sem acesso ao portal",
      operationUsers: "Usuários da operação",
      presets: "Predefinições",
      recurrenceType: "Tipo de recorrência",
      endTime: "Horário de Término",
      startTime: "Horário de Início",
      appointmentHours: "Horário do Agendamento",
      noSetTime: "Sem horário",
    },
    tooltip: {
      readOnly: "Define se o usuário pode apenas visualizar, sem poder alterar valores",
      canAccessReports: "Define se o usuário pode acessar relatórios da POS",
      printMerchantReceipt: "Define se o usuário pode imprimir a via do lojista",
      printOperatorReceipt: "Define o comportamento da impressão da via do operador ao finalizar uma transação",
      printUserReceipt:
        "Define o comportamento da impressão da via do cliente ao finalizar uma transação<br>" +
        "<ul>" +
        "<li>Os seguintes comprovantes serão afetados:</li>" +
        "<li>Recarga cashless</li>" +
        "<li>Recarga online cashless</li>" +
        "<li>Reembolso de saldo e recarga</li>" +
        "<li>Transferência de saldo</li>" +
        "<li>Estorno de venda de fichas e cashless</li>" +
        "<li>Devolução de fichas</li>" +
        "</ul>",
      canActivateCard: "Define se o usuário pode ativar cartões na POS",
      canSellRecharge: "Define se o usuário pode recarregar cartões",
      canConsumeRecharge: "Define se o usuário pode realizar vendas de produtos cashless",
      canSellOnlineRecharge: "Define se o usuário pode realizar recargas online",
      canCashUpAndCashDown: "Determina se o usuário pode ou não realizar sangrias/suprimentos", 
      searchOnlineRechargeOnCardAcquistion: "Consulta se o código do cartão ou documento informado possuem recargas online vinculadas no momento da ativação", 
      searchOnlineRechargeOnSale: "Consulta se o código do cartão ou documento possuem recargas online vinculadas quando o cartão estiver sem saldo",
      canRefundBalance: "Define se o usuário pode processar reembolsos e devoluções de saldo ou cartões",
      canReplaceCard: "Define se o usuário pode substituir um cartão por outro",
      canTransferBalance: "Define se o usuário pode transferir saldo de um cartão para outro",
      canDisplayBalanceAndStatement: "Define se o usuário pode visualizar o saldo e extrato do cartão",
      requiresDocument: "Define se é necessário solicitar um documento para ativar o cartão",
      cashlessPrintType: "Define o tipo de impressão para Cashless.",
      cashlessPrintCopies: "Define a quantidade de cópias para impressão Cashless.",
      requiresContactInfo: "Define se o contato é solicitado para ativar o cartão",
      canSellTicket: "Define se o usuário pode vender fichas",
      canConsumeTicket: "Define se o usuário pode fazer a validação/queima das fichas",
      canAutoBurnTicket: "Define se o usuário pode fazer a queima de fichas automáticamente das fichas",
      canReturnTicket: "Define se o usuário pode devolver ficha",
      ticketPrintType: "Define o tipo da impressão ficha.",
      ticketCopyQuantity: "Selecionar a quantidade de impressões de vias",
      exportPDF: "Exportar PDF",
      exportCSV: "Exportar CSV",
      exportBordereau: "Exportar borderô do evento",
      importCSV: `<small><span>Aqui vai algumas dicas para que você possa importar os seus produtos sem nenhum problema.</span><ul><li>Defina como separador a opção ponto e virgula ";" da planilha.</li><li>Verifique se o nome do produto, categoria ou o valor estejam certos.</li><li>Os valores dos produtos devem estar em centavos, apenas valores, sem caracteres como ponto ou vírgula. Ex: R$1,00 > 100</li></ul></small>`,
    },
    placeholder: {
      user: "Informe o usuário",
      password: "Informe a senha",
      document: "Informe o documento",
      fullName: "Informe o nome",
      phone: "Informe o celular",
      email: "Informe o e-mail",
      eventSearch: "Pesquise pelo nome do evento",
      proofOfTransfer: "Inserir comprovante de transferência",
      general: "Geral",
      ticket: "Ficha",
      productName: "Informe o nome do produto",
      categoryName: "Informe o nome da categoria",
      operationName: "Informe o nome da operação",
      selectCategory: "Selecione a categoria",
      maximumSize: "Tamanho máximo 2MB",
      productValue: "Informe o valor do produto",
      productConsumption: "Informe o consumo do produto",
      notes: "Digite aqui observações do evento, cobranças extras, perda de equipamento entre outros...",
    },
    options: {
      specificDate: "Data específica",
      recurring: "Recorrente",
      activePeriod: "Período Ativo",
      inactivePeriod: "Período Inativo",
      entry: "Entrada",
      exit: "Saída"
    }
  },
  alt: {
    thinkingPerson: "Imagem de uma pessoa pensando.",
  },
  cardTitle: {
    totalSold: "Total Vendido",
    totalConsumed: "Total Consumido",
    toBeConsumed: "Falta Consumir",
    ranking: {
      products: "Ranking de Produtos",
      clients: "Ranking de Clientes",
      operations: "Ranking de Operações",
    },
    paymentMethod: "Formas de Pagamento",
    cardsAndBands: "Cartões/Pulseiras",
    devices: "Dispositivos",
    otherTransactions: "Outras Transações",
    consumptionPerHour: "Consumo Por Hora",
    details: "Detalhamento",
    updateRegisteredData: "Atualizar dados cadastrados",
  },
  header: {
    from: "De",
    until: "Até",
    to: "a",
    seconds: "segundos",
    date: "Data",
    hour: "Hora",
    updating: "Atualizando...",
    updatingIn: "Atualizando em",
    updatingData: "Atualizando dados em",
    noSetDate: "Sem data definida",
    noLinkedEvent: "Usuário não possui evento vinculado.",
    noLinkedCard: "Usuário não possui cartão vinculado.",
    noEventFound: "Nenhum evento encontrado.",
    noResultFound: "Nenhum resultado encontrado.",
    resultsPerPage: "Resultados por página",
    noHistory: "Nenhum histórico para exibir",
  },
  ageClassification: {
    free: "Livre de restrição",
    prohibitedUnder: "Proibido para menores de {age} anos"
  },
  icons: {
    drinks: "Bebidas",
    food: "Comida",
    souvenirs: "Souvenirs",
    car: "Carro (Estacionamento)",
    cocktail: "Drinks",
    others: "Outros",
  },
  weekDays: {
    title: "Dias da semana",
    everyDay: "Todos os dias",
    sunday: "Domingo",
    monday: "Segunda-feira",
    tuesday: "Terça-feira",
    wednesday: "Quarta-feira",
    thursday: "Quinta-feira",
    friday: "Sexta-feira",
    saturday: "Sábado",
  },
  actions: {
    actions: "Ações",
    yes: "Sim",
    no: "Não",
    optional: "Opcional",
    search: "Pesquisar",
    apply: "Aplicar",
    save: "Salvar",
    confirm: "Confirmar",
    register: "Cadastrar",
    saveRegistration: "Salvar Cadastro",
    clear: "Limpar",
    next: "Próxima",
    edit: "Editar",
    delete: "Deletar",
    remove: "Remover",
    duplicate: "Duplicar",
    checkAll: "Marcar Todas",
    uncheckAll: "Desmarcar Todas",
    addAll: "Adicionar todos",
    removeAll: "Remover todos",
    editEvent: "Editar evento",
    duplicateEvent: "Duplicar evento",
    deleteEvent: "Deletar evento",
    newGroup: "Novo Grupo",
    newProduct: "Novo Produto",
    newAppointment: "Novo Agendamento",
    editProduct: "Editar Produto",
    importSpreadsheet: "Importar Planilha",
    onlineMenu: "Cardápio Online",
    copyLink: "Copiar Link",
    download: "Baixar",
    newCategory: "Nova categoria",
    newOperation: "Nova operação",
    createOperations: "Criar Operações",
    configureOperations: "Configurar Operações",
    addOperations: "Adicionar Operações",
    editOperations: "Editar Operações",
    addUsers: "Adicionar usuários",
    addUsersInBulk: "Adicionar Usuários em Massa",
    addUsersInBulkDescription: "Crie múltiplos usuários com a mesma configuração para todas as operações de uma só vez.",
    noRegisteredOperations: "Sem operações cadastradas",
    editCategory: "Editar categoria",
    notDefined: "Ação não definida",
    deleteEventConfirmation: "Deseja deletar o evento {event}?",
    requestRefund: "Solicitar reembolso",
    refundRequest: "Solicitação de Reembolso",
    refundDeadline: "O prazo para solicitação de reembolso será de",
    balanceToBeRefunded: "Saldo a ser reembolsado",
    refundFee: "Taxa Reembolso",
    deactivateCategory: "Inativar Categoria",
    categoryDeactivationConfirmation: "A inativação da categoria <strong> {category} </strong> pode afetar a disponibilidade de vários produtos. Pressione CONFIRMAR para confirmar a ação.",
    pixTransfer: "Será feita uma transferência PIX para o documento vinculado ao seu cadastro.<br><br>A chave PIX CPF é a melhor maneira para garantir-se que o solicitante será o beneficiário do reembolso.<u>Certifique-se que o seu CPF está cadastrado e apto a receber transações deste tipo.</u><br><br>Caso não queira mantê-la, a chave pode ser desativada uma vez confirmado o reembolso.",
    selectCard: "Selecione um cartão",
    confirmTransferRequest: "Confirmar a solicitação de transferência no valor de",
    finalizeRequest: "Finalizar solicitação",
    processingRequest: "Processando solicitação",
    directedToRanking: "Clique aqui para ser direcionado ao relatório referente a este ranking.",
    searchForInformation: "Buscar informações",
    searchForWithdrawals: "Buscar saques",
    requestWithdrawal: "Solicitar saque",
    pay: "Pagar",
    payThirdParties: "Pagar terceiros",
  },
  digitalWallet: {
    availableBalance: "Saldo disponível",
    heldBalance: "Saldo retido",
    waitingForRelease: "Aguardando liberação",
    transferredAmount: "Valor transferido",
    totalRevenue: "Receita Total",
    elevenInvoices: "Faturas Eleven",
    acquirerFees: "Taxas da Adquirente",
    waitingForAcquirerRelease: "Aguardando liberação do valor pela adquirente.",
    amountTransferredToProducer: "Valor já repassado ao produtor.",
    netRevenueAfterFees: "Receita líquida descontado as taxas da adquirente.",
    availableBalanceForWithdrawal: "Saldo disponível para saque",
    withdrawalProcessingInfo: "Todos os repasses solicitados (saque ou pagar terceiros) serão processados em 48 horas úteis.",
    withdrawalAmount: "Valor do saque",
    description: "Descrição",
    payThirdPartiesInfo: "Faça o pagamento de fornecedores e contas de terceiros com o seu saldo disponível.",
    paymentAmount: "Valor do pagamento",
    accountDataForPayment: "Dados da conta para pagamento",
    recipient: "Destinatário",
    insertRecipient: "Insira o destinatário",
    checkingAccount: "Conta corrente",
    savingsAccount: "Poupança",
    createPaymentIdentifier: "Crie um identificador do pagamento.",
    insertTransferReceipt: "Inserir comprovante de transferência",
    emailCodeValidation: "Validação de código por e-mail",
    enterCodeToFinish: "Digite o código enviado para o e-mail cadastrado para finalizar a operação",
    tokenCode: "Código Token",
    insertCodeSentByEmail: "Insira o código enviado por e-mail",
    cancelTransferRequest: "Você deseja <span>cancelar</span> a solicitação de transferência no valor de <span>R$ {value}</span> ?",
    rejectTransferRequest: "Você deseja <span>rejeitar</span> a solicitação de transferência no valor de <span>R$ {value}</span> ?",
    rejectionReason: "Justificativa da rejeição",
    informRejectionReason: "Informe a justificativa da rejeição.",
    cancellationReason: "Justificativa do cancelamento",
    informCancellationReason: "Informe a justificativa do cancelamento.",
    giveUp: "Desistir",
    reject: "Rejeitar",
    noDataToView: "Sem dados para visualizar.",
    noDataFound: "Nenhum dado encontrado.",
    noInfoToGenerateFile: "Sem informações para gerar o arquivo",
    searchingWithdrawals: "Buscando por saques.",
  },
  // Alertas do Back
  schedule: {
    creation: {
      success: "Agendamento criado com sucesso",
      failure: "Falha ao criar agendamento",
    },
    editing: {
      success: "Agendamento editado com sucesso",
      failure: "Falha ao editar agendamento",
    },
    deletion: {
      success: "Agendamento deletado com sucesso",
      failure: "Falha ao deletar agendamento",
    },
    list: {
      failure: "Falha ao listar agendamentos",
    },
  },
  register: {
    open: {
      fail: "Falha ao abrir registro",
    },
    value: {
      fail: "Falha ao registrar valor",
    },
  },
  cards: {
    reason: "Motivo",
    blockCard: "Bloquear o cartão",
    selectReason: "Selecione o motivo do bloqueio",
    cardHistory: "Histórico do cartão",
    inUse: {
      failure: "Falha ao listar cartões em uso",
    },
    balance: {
      failure: "Falha ao consultar saldo do cartão",
    },
    search: {
      error: "Erro ao buscar cartão",
    },
    block: {
      block: "Bloquear",
      success: "Cartão bloqueado com sucesso",
      failure: "Falha ao bloquear cartão",
    },
  },
  wallet: {
    values: {
      failure: "Falha ao consultar valores da carteira",
    },
  },
  category: {
    search: {
      failure: "Falha ao buscar categorias",
    },
    creation: {
      success: "Categoria criada com sucesso",
      failure: "Falha ao criar categoria",
    },
    editing: {
      success: "Categoria editada com sucesso",
      failure: "Falha ao editar categoria",
    },
    deletion: {
      success: "Categoria deletada com sucesso",
      failure: "Falha ao deletar categoria",
    },
    restoration: {
      success: "Categoria restaurada com sucesso",
      failure: "Falha ao restaurar categoria",
    },
  },
  config: {
    editing: {
      failure: "Falha ao editar configurações",
    },
    get: {
      failure: "Falha ao obter configurações",
    },
  },
  bankingAccount: {
    listActive: {
      failure: "Falha ao listar contas ativas",
    },
    creation: {
      failure: "Falha ao criar conta bancária",
    },
    listByPerson: {
      failure: "Falha ao listar contas por pessoa",
    },
    changeAccountStatus: {
      failure: "Falha ao alterar status da conta",
    },
    update: {
      failure: "Falha ao atualizar conta bancária",
    },
  },
  equipment: {
    list: {
      failure: "Falha ao listar equipamentos",
    },
    status: {
      failure: "Falha ao consultar status do equipamento",
    },
    creation: {
      success: "Equipamento criado com sucesso",
      failure: "Falha ao criar equipamento",
    },
    listByEvent: {
      failure: "Falha ao listar equipamentos por evento",
    },
    listAcquirers: {
      failure: "Falha ao listar adquirentes de equipamentos",
    },
  },
  event: {
    refund: {
      notAllowed: "Reembolsos não são permitidos para este evento"
    },
    massEquipmentImport: {
      success: "Importação massiva de equipamentos realizada com sucesso",
      failure: "Falha na importação massiva de equipamentos",
    },
    list: {
      failure: "Falha ao listar eventos",
    },
    listById: {
      failure: "Falha ao listar evento por ID",
    },
    listByOperatorId: {
      failure: "Falha ao listar eventos por operador",
    },
    dates: {
      failure: "Falha ao consultar datas do evento",
    },
    editing: {
      success: "Evento editado com sucesso",
      failure: "Falha ao editar evento",
    },
    creation: {
      success: "Evento criado com sucesso",
      failure: "Falha ao criar evento",
    },
    deletion: {
      success: "Evento deletado com sucesso",
      failure: "Falha ao deletar evento",
    },
    duplication: {
      success: "Evento duplicado com sucesso",
      failure: "Falha ao duplicar evento",
    },
    licenses: {
      failure: "Falha ao consultar licenças do evento",
    },
    menu: {
      failure: "Falha ao carregar menu do evento",
    },
  },
  paymentMethod: {
    list: {
      failure: "Falha ao listar métodos de pagamento",
    },
  },
  gateway: {
    list: {
      failure: "Falha ao listar gateways",
    },
  },
  management: {
    dailyRevenue: {
      failure: "Falha ao consultar receita diária",
    },
    topEvents: {
      failure: "Falha ao consultar os principais eventos",
    },
    cardsAndBracelets: {
      failure: "Falha ao consultar cartões e pulseiras",
    },
    paymentMethodRank: {
      failure: "Falha ao consultar o ranking de métodos de pagamento",
    },
    totalTransacted: {
      failure: "Falha ao consultar o total transacionado",
    },
    revenue: {
      failure: "Falha ao consultar receita",
    },
  },
  graph: {
    hourConsumption: {
      failure: "Falha ao consultar o consumo por hora",
    },
  },
  userGroup: {
    creation: {
      failure: "Falha ao criar grupo de usuários",
      success: "Sucesso ao criar grupo de usuários"
    },
    deletion: {
      success: "Grupo de usuários deletado com sucesso",
      failure: "Falha ao deletar grupo de usuários",
    },
    restoration: {
      success: "Grupo de usuários restaurado com sucesso",
      failure: "Falha ao restaurar grupo de usuários",
    },
    editing: {
      success: "Grupo de usuários editado com sucesso",
      failure: "Falha ao editar grupo de usuários",
    },
    list: {
      failure: "Falha ao listar grupos de usuários",
    },
    link: {
      success: "Usuário vinculado ao grupo com sucesso",
      failure: "Falha ao vincular usuário ao grupo",
    },
    unlink: {
      success: "Usuário desvinculado do grupo com sucesso",
      failure: "Falha ao desvincular usuário do grupo",
    },
  },
  indicators: {
    grossAmount: {
      failure: "Falha ao consultar o valor bruto",
    },
    grossAmountDetails: {
      failure: "Falha ao consultar os detalhes do valor bruto",
    },
    totalConsumed: {
      failure: "Falha ao consultar o total consumido",
    },
    lackConsumption: {
      failure: "Falha ao consultar a ausência de consumo",
    },
    lackConsumptionDetails: {
      failure: "Falha ao consultar os detalhes de ausência de consumo",
    },
  },
  fakeEvent: {
    verifyDomainAvailability: {
      failure: "Falha ao verificar a disponibilidade do domínio",
    },
    creation: {
      failure: "Falha ao criar evento falso",
    },
    listProducers: {
      failure: "Falha ao listar produtores",
    },
    collectProducer: {
      failure: "Falha ao coletar dados do produtor",
    },
    listAllEvents: {
      failure: "Falha ao listar todos os eventos",
    },
    listEventsProducer: {
      failure: "Falha ao listar eventos do produtor",
    },
    deletion: {
      failure: "Falha ao deletar evento falso",
    },
  },
  operations: {
    list: {
      failure: "Falha ao listar operações",
    },
    get: {
      failure: "Falha ao consultar operação",
    },
    editing: {
      success: "Operação editada com sucesso",
      failure: "Falha ao editar operação",
    },
    deletion: {
      success: "Operação deletada com sucesso",
      failure: "Falha ao deletar operação",
    },
    massCreation: {
      success: "Criação massiva de operações realizada com sucesso",
      failure: "Falha na criação massiva de operações",
    },
    duplication: {
      success: "Operação duplicada com sucesso",
      failure: "Falha ao duplicar operação",
    },
  },
  profile: {
    list: {
      failure: "Falha ao listar perfis",
    },
  },
  person: {
    login: {
      notFound: "Usuário não encontrado",
      invalidCredentials: "Credenciais inválidas",
      failure: "Falha ao fazer login",
    },
    personEnv: {
      failure: "Falha ao consultar o ambiente da pessoa",
    },
    creation: {
      failure: "Falha ao criar pessoa",
    },
    get: {
      failure: "Falha ao consultar pessoa",
    },
    update: {
      failure: "Falha ao atualizar pessoa",
    },
    changePassword: {
      success: "Senha alterada com sucesso",
      failure: "Falha ao alterar senha",
    },
    recoverPassword: {
      success: "E-mail para redefinição de credenciais enviado para o endereço vinculado ao seu documento.",
      failure: "Falha ao recuperar acesso",
    },
  },
  product: {
    list: {
      failure: "Falha ao listar produtos",
    },
    creation: {
      deletion: "Produto criado e excluído",
      failure: "Falha ao criar produto",
    },
    massCreation: {
      failure: {
        invalidContent: "Falha na criação massiva: conteúdo inválido",
      },
      success: "Criação massiva de produtos realizada com sucesso",
      failure: "Falha na criação massiva de produtos",
    },
    editing: {
      success: "Produto editado com sucesso",
    },
    deletion: {
      success: "Produto deletado com sucesso",
      failure: "Falha ao deletar produto",
    },
    restoration: {
      success: "Produto restaurado com sucesso",
      failure: "Falha ao restaurar produto",
    },
    getById: {
      failure: "Falha ao buscar produto por ID",
    },
    ocult: {
      success: "Produto ocultado com sucesso",
    },
    menu: {
      success: "Menu de produtos carregado com sucesso",
    },
  },
  withdraw: {
    creationProducer: {
      success: "Saque criado para produtor com sucesso",
    },
    creationThirdParty: {
      success: "Saque criado para terceiros com sucesso",
      failure: "Falha ao criar saque para terceiros",
    },
    auth: {
      success: "Autorização de saque realizada com sucesso",
      failure: "Falha na autorização de saque",
    },
    cancel: {
      success: "Saque cancelado com sucesso",
      failure: "Falha ao cancelar saque",
    },
    approve: {
      success: "Saque aprovado com sucesso",
      failure: "Falha ao aprovar saque",
    },
    reject: {
      success: "Saque rejeitado com sucesso",
      failure: "Falha ao rejeitar saque",
    },
    editing: {
      success: "Saque editado com sucesso",
      failure: "Falha ao editar saque",
    },
  },
  sync: {
    transactions: {
      failure: "Falha ao sincronizar transações",
    },
    mov: {
      failure: "Falha ao sincronizar movimentações",
    },
    cards: {
      failure: "Falha ao sincronizar cartões",
    },
    tokens: {
      failure: "Falha ao sincronizar tokens",
    },
    person: {
      failure: "Falha ao sincronizar dados da pessoa",
    },
  },
  user: {
    login: {
      notFound: "Usuário não encontrado",
      invalidCredentials: "Credenciais inválidas",
      failure: "Falha ao realizar login do usuário",
    },
    list: {
      failure: "Falha ao listar usuários",
    },
    getById: {
      failure: "Falha ao buscar usuário por ID",
    },
    creation: {
      success: "Usuário criado com sucesso",
      failure: "Falha ao criar usuário",
    },
    deletion: {
      success: "Usuário deletado com sucesso",
      failure: "Falha ao deletar usuário",
    },
    editing: {
      success: "Usuário editado com sucesso",
      failure: "Falha ao editar usuário",
    },
    restoration: {
      success: "Usuário restaurado com sucesso",
      failure: "Falha ao restaurar usuário",
    },
    changePassword: {
      success: "Senha do usuário alterada com sucesso",
      failure: "Falha ao alterar senha do usuário",
    },
  },
  categories: {
    search: {
      id: "ID",
      category: "Categoria",
      icon: "Ícone",
      order: "Ordem",
      ageClassification: "Classificação Etária",
      active: "Ativo"
    }
  },
  cardList: {
    statement: {
      code: "Código",
      document: "Documento",
      client: "Cliente",
      balance: "Saldo",
      status: "Status",
    }
  },
  group: {
    search: {
      id: "ID",
      description: "Descrição",
    }
  },
  report: {
    productSales: {
      category: "Categoria",
      product: "Produto",
      sold: "Vendido",
      refunded: "Devolvido",
      total: "Total",
      unitValue: "Valor Unitário",
      totalValue: "Valor Total"
    },
    operationSales: {
      operation: "Operação",
      category: "Categoria",
      product: "Produto",
      sold: "Vendido",
      refunded: "Devolvido",
      total: "Total",
      unitValue: "Valor Unitário",
      totalValue: "Valor Total"
    },
    paymentMethodSales: {
      equipment: "Equipamento",
      cash: "Dinheiro",
      debit: "Débito",
      credit: "Crédito",
      pix: "Pix",
      banricompras: "Banricompras",
      courtesyAndRechage: "Recarga - Cortesia",
      activation: "Ativação",
      activationCourtesy: "Ativação - Cortesia",
      other: "Outras Formas",
      value: "Valor",
      consumption: "Consumido",
      courtesyConsumption: "Consumo - Cortesia"
    },
    userSales: {
      operator: "Operador",
      cash: "Dinheiro",
      debit: "Débito",
      credit: "Crédito",
      pix: "Pix",
      banricompras: "Banricompras",
      activation: "Ativação",
      discount: "Descontos",
      serviceFee: "Taxa Serviço",
      other: "Outras Formas",
      value: "Valor",
      consumption: "Consumido",
    },
    detailed: {
      movId: "ID da movimentação",
      registerId: "ID do caixa",
      creationDate: "Data de criação",
      lastUpdated: "Última alteração",
      type: "Tipo",
      equipment: "Equipamento",
      operation: "Operacao",
      operator: "Operador",
      amountPaid: "Valor pago",
      amountConsumed: "Valor consumido",
      paymentStatement: "Extrato pagamento",
      transactionStatement: "Extrato transação",
    },
    detailedPayments: {
      movId: "ID da movimentação",
      paymentId: "ID da pagamento",
      registerId: "ID do caixa",
      creationDate: "Data de criação",
      lastUpdate: "Última alteração",
      equipment: "Equipamento",
      operation: "Operacao",
      operator: "Operador",
      type: "Tipo",
      paymentMethod: "Forma de pagamento",
      value: "Valor",
      total: "Total",
      paymentStatus: "Status do pagamento",
      card: "Cartão",
      document: "Documento",
      balanceInCard: "Saldo no cartão",
      cardSequential: "Sequencial do cartão",
      pdvSequential: "Sequencial do PDV",
      supervisor: "Supervisor",
      details: "Detalhes",
      nsu: "NSU",
      flag: "Bandeira",
      tefReturn: "Comprovante"
    },
    detailedTransactions: {
      movId: "ID da movimentação",
      transactionId: "ID da transação",
      registerId: "ID do caixa",
      creationDate: "Data de criação",
      lastUpdate: "Última alteração",
      equipment: "Equipamento",
      operation: "Operacao",
      operator: "Operador",
      type: "Tipo",
      value: "valor",
      status: "Status",
      product: "Produto",
      productCategory: "Categoria do produto",
      supervisor: "Supervisor",
      card: "Cartão",
      document: "Documento",
      balanceInCard: "Saldo no cartão",
    },
    register: {
      operation: "Operação",
      user: "Usuário",
      equipment: "Equipamento",
      opening: "Abertura",
      closing: "Fechamento",
      cash: "Dinheiro",
      debit: "Débito",
      credit: "Crédito",
      pix: "Pix",
      onlineRecharge: "Recarga Online",
      rechargeTotal: "Total Recargas",
      totalTokenSold: "Total Vendido Ficha",
      totalConsumed: "Total Consumido",
      supplyRegister: "Suprimento de caixa",
      cashFloat: "Sangria de caixa",
      inHandsCash: "Dinheiro em Mãos",
      tokenRefund: "Devolução de Ficha",
      balanceRefund: "Devolução de saldo",
      courtesyRecharge: "Recarga Cortesia",
      courtesyConsumption: "Consumo Cortesia",
      bonus: "Bônus",
      advanced: "Antecipado",
      voucher: "Voucher",
      other: "Outros",
      registerId: "ID Caixa"
    },
    clients: {
      name: "Nome",
      document: "Documento",
      telephone: "Telefone",
      email: "E-mail",
      dateOfBirth: "Data de Nascimento",
      maxValueSpent: "Valor Máximo Gasto"
    },
    finance: {
      equipment: "Equipamento",
      cash: "Dinheiro",
      debit: "Débito",
      credit: "Crédito",
      totalCredit: "Crédito Total",
      pix: "Pix",
      onlineRecharge: "Recarga Online",
      totalSold: "Total Recargas",
      totalTokenSold: "Total Vendido Ficha",
      totalConsumed: "Total Consumido",
      registerSupply: "Suprimento de caixa",
      cashFloat: "Sangria de caixa",
      inHandsCash: "Dinheiro em Mãos",
      tokenRefund: "Devolução de Ficha",
      balanceRefund: "Devolução de saldo",
      courtesyRecharge: "Recarga Cortesia",
      courtesyConsumption: "Consumo Cortesia",
      bonus: "Bônus",
      advanced: "Antecipado",
      voucher: "Voucher",
      other: "Outros"
    },
    courtesy: {
      equipment: "Equipamento",
      operation: "Operação",
      user: "Usuário",
      supervisor: "Supervisor",
      dateTime: "Data/Hora",
      card: "Cartão",
      name: "Nome",
      document: "Documento",
      type: "Tipo",
      product: "Produto",
      value: "Valor"
    },
    reprinting: {
      supervisor: "Supervisor",
      equipment: "Equipamento",
      operation: "Operação",
      user: "Usuário",
      dateTime: "Data/Hora",
      card: "Cartão",
      document: "Documento",
      type: "Tipo",
      status: "Status",
      product: "Produto",
      value: "Valor"
    },
    productRefund: {
      equipment: "Equipamento",
      operation: "Operação",
      operator: "Operador",
      dateTime: "Data/Hora",
      cardId: "ID Cartão",
      cpf: "CPF",
      type: "Tipo",
      product: "Produto",
      value: "Valor"
    },
    balanceRefund: {
      equipment: "Equipamento",
      operation: "Operação",
      operator: "Operador",
      product: "Produto",
      dateTime: "Data/Hora",
      cardId: "ID Cartão",
      cpf: "CPF",
      type: "Tipo",
      refundedValue: "Valor Devolvido"
    },
    cardOnlineRecharge: {
      dateTime: "Data e hora",
      document: "Documento",
      card: "Cartão",
      name: "Nome",
      value: "Valor",
      status: "Status"
    },
    receivables: {
      operator: "Operador",
      credit: "Crédito",
      liquidCredit: "Crédito Líquido",
      debit: "Débito",
      liquidDebit: "Débito Líquido",
      pix: "PIX",
      liquidPix: "PIX Líquido",
      total: "Total",
      liquidTotal: "Total Líquido"
    },
    cashFloatAndRegisterSupply: {
      dateTime: "Data e Hora",
      supervisor: "Supervisor",
      equipment: "Equipamento",
      operator: "Operador",
      paymentType: "Tipo Pagamento",
      value: "Valor"
    },
    products: {
      id: "ID",
      categoryId: "ID Categoria",
      image: "Imagem",
      product: "Produto",
      category: "Categoria",
      value: "Valor",
      type: "Tipo",
      active: "Ativo",
      qrCode: "QR Code",
      useObservation: "Utiliza Obs.",
      observationTitle: "Título Obs.",
      enabled: "Habilitado"
    },
    operations: {
      id: "ID",
      fantasy: "Fantasia",
      reason: "Razão"
    },
    equipmentByEvent: {
      equipment: "Equipamento",
      equipmentId: "ID do equipamento",
      event: "Evento",
      initialDate: "Data inicial",
      finalDate: "Data final",
      acquirer: "Adquirente",
      eventId: "ID do evento"
    },
    withdrawals: {
      identifier: "Identificador",
      updateDate: "Data de Atualização",
      applicant: "Solicitante",
      status: "Status",
      approval: "Aprovação",
      withdrawalValue: "Valor do Saque",
      withdrawalId: "ID do Saque",
      document: "Documento",
      approvalDate: "Data de Aprovação",
      receipt: "Comprovante",
      justification: "Justificativa",
      observation: "Observação"
    },
    users: {
      id: "ID",
      name: "Nome",
      user: "Usuário",
      profile: "Perfil",
      operation: "Operação",
      creation: "Criação",
      active: "Ativo"
    },
    equipmentStatus: {
      user: "Usuário",
      equipment: "Equipamento",
      batteryLevel: "Nível Bateria",
      charging: "Está Carregando",
      registerOpen: "Caixa Aberto",
      appVersion: "Versão Aplicativo",
      networkType: "Tipo Rede",
      signalStrength: "Potência Sinal",
      ssid: "SSID",
      ip: "IP",
      transactionCount: "Quantidade Transações",
      notSyncedTransactionCount: "Quantidade Transações Não Sincronizadas",
      lastCommunication: "Última Comunicação",
      uptime: "Uptime",
      latency: "Latência",
      registerBalance: "Saldo Caixa",
      latitude: "Latitude",
      longitude: "Longitude",
      applications: "Aplicações",
      acquirer: "Adquirente"
    },
    attendant: {
      attendant: "Atendente",
      totalValue: "Valor Total",
      percentage: "Porcentagem",
    },
    cardStatement: {
      card: "Cartão",
      document: "Documento",
      date: "Data",
      item: "Item",
      value: "Valor",
      balance: "Saldo",
      paymentMethod: "Forma de Pagamento",
      operator: "Operador",
      operation: "Operacao",
      paymentStatus: "Status Pagamento",
      transactionStatus: "Status Transação",
      effect: "Efeito",
      equipment: "Equipamento"
    },
  },
  enum: {
    blockReason: {
      negativeBalance: "Saldo negativo",
      maxWebRecharge: "Valor de recarga acima do máximo permitido para Web",
      minWebRecharge: "Valor de recarga abaixo do mínimo permitido para Web",
      maxPDVRecharge: "Valor de recarga acima do máximo permitido para PDV",
      minPDVRecharge: "Valor de recarga abaixo do mínimo permitido para PDV",
      maxTCIRecharge: "Valor de recarga acima do máximo permitido para TCI",
      minTCIRecharge: "Valor de recarga abaixo do mínimo exigido para TCI",
      overLimitBalance: "Saldo acima do limite permitido.",
      documentMissing: "Cartão sem documento vinculado",
      lose: "Perda",
      theft: "Roubo",
      equipmentMissing: "Cartão sem equipamento ou com equipamento inválido vinculado",
      webRefund: "Reembolso",
      balanceTransference: "Transferência de saldo",
      cardReuse: "Reutilização do Cartão (Desbloqueio)",
      damagedCard: "Cartão danificado",
    },
    cardStatus: {
      freeCard: "Liberado",
      active: "Ativado",
      blocked: "Bloqueado",
      blockedBlacklist: "Bloqueado - Lista Negra",
      blockedCrc: "Bloqueado - Cyclic Redundancy Check",
      blockedIncorrectPassword: "Bloqueado - Senha Incorreta",
      blockedCardReturned: "Bloqueado - Cartão foi devolvido",
      dateOfBirthChanged: "Data de nascimento foi alterada",
      passwordChanged: "Senha foi alterada",
      restrictionChanged: "Restrição",
   },
    cardType: {
      standard: "Cartão comum",
      courtesy: "Cartão cortesia",
    },
    pixKeyType: {
      email: "Email",
      cpf: "CPF",
      cnpj: "CNPJ",
      cellphone: "Telefone",
      random: "Chave aleatória",
    },
    bankAccountType: {
      currentIndividual: "Conta Corrente de Pessoa Física",
      currentBusiness: "Conta Corrente de Pessoa Jurídica",
      savingsIndividual: "Poupança de Pessoa Física",
      savingsBusiness: "Poupança de Pessoa Jurídica",
    },
    acquirer: {
      pagseguro: "PagSeguro",
      vero: "Vero",
      softwareexpress: "SoftwareExpress",
      auttar: "Auttar",
      unknown: "Não informado",
    },
    equipmentType: {
      server: "SERVIDOR",
      parkingMeter: "PARQUÍMETRO",
      turnstile: "CATRACA",
      barrier: "CANCELA",
      tps: "TPS",
      parkingSensor: "SENSOR ESTACIONAMENTO",
      tci: "TOTEM",
      pdv: "smartPOS",
      supermedia: "SUPERMÍDIA",
      maestroTotemViewer: "MAESTRO TOTEM / MAESTRO VISUALIZADOR",
      maestroEvaluator: "MAESTRO AVALIADOR",
      maestroMobileCaller: "MAESTRO CHAMADOR MOBILE",
      multiKiosk: "MULTI KIOSK",
      weatherStation: "ESTAÇÃO METEOROLÓGICA",
      bowlingLane: "BOLICHE PISTA",
      bowlingPinIndicator: "BOLICHE PINIDICATION",
      bowlingReservation: "BOLICHE RESERVA",
      bowlingOnOff: "BOLICHE ON/OFF",
      bowlingBar: "BOLICHE BAR",
      parkingConcentrator: "CONCENTRADOR ESTACIONAMENTO",
      mobilePointOfSale: "PDV MOBILE",
      handheld: "HANDHELD",
      other: "Outro",
    },
    cardAction: {
      read: "Ler dados",
      write: "Escrever dados",
      auth: "Autenticar setor",
    },
    cardData: {
      cardNotFormatted: "Cartão não formatado",
    },
    keyType: {
      A: "Usar a chave A",
      B: "Usar a chave B",
    },
    cardDocumentType: {
      cpf: "Cartão ativo com CPF",
      free: "Cartão ativo sem documento",
    },
    movType: {
      activation: "Aquisição de cartão",
      recharge: "Recarga cashless",
      cashlessSale: "Consumo cashless",
      tokenSale: "Emissão de Ficha",
      onlineRecharge: "Recarga Online",
      cardReplacement: "Substituição de cartão",
      cashSupply: "Suprimento de caixa",
      cashWithdrawal: "Sangria de caixa",
      refund: "Reembolso",
      balanceTransfer: "Trâsnferência de saldo",
      tokenReturn: "Devolução de ficha",
      cardBlock: "Bloqueio de cartão",
      onlineRefund: "Reembolso online",
      other: "-",
    },
    paymentStatus: {
      success: "Sucesso",
      canceled: "Cancelado",
      pending: "Pendente",
      reversed: "Estornado",
      available: "Disponível",
      reserved: "Reservado",
    },
    paymentType: {
      recharge: "Recarga",
      cardAcquisition: "Aquisição de cartão",
      cardReturn: "Devolução de ativação",
      refundedBalance: "Devolução de saldo",
      consumption: "Consumo",
      tokenIssuance: "Emissão de Ficha",
      tokenRedemption: "Resgate de Ficha",
      refundInProgress: "Reembolso em andamento",
      cancelledRefund: "Reembolso Cancelado",
      refundFee: "Taxa de reembolso",
      productRefund: "Estorno de produto",
      balanceTransfer: "Transferência de saldo",
      postPaidConsumption: "Consumo pós-pago",
      paymentRefund: "Estorno de pagamento",
      cardReuse: "Reutilização de cartão",
      withdrawalTransfer: "Transferência (retirada)",
      applicationTransfer: "Transferência (aplicação)",
      tokenReturn: "Devolução ficha",
      cashlessReprint: "Reimpressão cashless",
      tokenReprint: "Reimpressão ficha",
      cardBlockRegistration: "Registro de bloqueio de cartão",
      cashlessConsumptionRefund: "Estorno de Consumo Cashless",
      fractioningOperation: "Operação de fracionamento",
      cashboxOpening: "Abertura de caixa",
      cashWithdrawal: "Sangria de caixa",
      cashSupply: "Suprimento de caixa",
      cashboxClosing: "Encerramento de caixa",
      cashBreakage: "Quebra de caixa",
      cashSurplus: "Sobra de caixa",
      cashboxClosure: "Fechamento de caixa",
      feeTransfer: "Taxa de repasse",
      rentalFee: "Taxa de Locação",
      minimumFee: "Taxa mínima",
      technicalHourFee: "Taxa da hora técnica",
      serviceFee: "Taxa de serviço",
      discount: "Desconto",
      rechargeRefund: "Estorno de Recarga",
      default: "Pagamento",
    },
    paymentMethod: {
      antecipado: "Antecipado",
      cortesia: "Cortesia",
      credito: "Crédito",
      ficha: "Ficha",
      dinheiro: "Dinheiro",
      pix: "Pix",
      debito: "Débito",
      cashless: "Cashless",
      banricompras: "Banricompras",
      credito_parcelado: "Crédito Parcelado",
      bonus: "Bônus",
      recarga_online: "Recarga Online",
      outros: "Outros",
    },
    productObservation: {
      yes: "Sim",
      optional: "Opcional",
      no: "Não",
    },
    refundSituation: {
      finished: "Reembolso concluído",
      waitingForConfirmation: "Reembolso aguardando confirmação",
      inProgress: "Reembolso em andamento",
      incorrectData: "Dados de reembolso incorretos",
      rejected: "Reembolso recusado",
    },
    withdrawalStatus: {
      pending: "Pendente",
      approved: "Aprovado",
      cancelled: "Cancelado",
      rejected: "Rejeitado",
      waitingForAuthentication: "Aguardando Autenticação",
    },
    transactionStatus: {
      success: "Sucesso",
      canceled: "Cancelado",
      pending: "Pendente",
      reversed: "Estornado",
      available: "Disponível",
      reserved: "Reservado",
    },
    transactionType: {
      recharge: "Recarga",
      cashlessActivation: "Ativação Cashless",
      cashlessActivationReturn: "Devolução de Ativação Cashless",
      cashlessBalanceReturn: "Devolução de Saldo Cashless",
      consumption: "Consumo",
      tokenIssuance: "Emissão de Fichas",
      tokenRedemption: "Resgate de Ficha",
      onlineRefund: "Reembolso Online",
      cancelledRefund: "Reembolso Cancelado",
      refundFee: "Taxa de reembolso",
      productRefund: "Estorno de produto",
      balanceTransfer: "Transferência de saldo",
      postPaidConsumption: "Consumo pós-pago",
      paymentRefund: "Estorno de pagamento",
      cardReuse: "Reutilização de cartão",
      withdrawalTransfer: "Transferência (retirada)",
      applicationTransfer: "Transferência (aplicação)",
      tokenReturn: "Devolução de Fichas",
      cashlessReprint: "Reimpressão de Consumo Cashless",
      tokenReprint: "Reimpressão de Fichas",
      cardBlockRegistration: "Registro de bloqueio de cartão",
      cashlessConsumptionRefund: "Estorno de Consumo Cashless",
      fractioningOperation: "Operação de fracionamento",
      cashboxOpening: "Abertura de caixa",
      cashWithdrawal: "Sangria de caixa",
      cashSupply: "Suprimento de caixa",
      cashboxClosing: "Encerramento de caixa",
      cashBreakage: "Quebra de caixa",
      cashSurplus: "Sobra de caixa",
      cashboxClosure: "Fechamento de caixa",
      feeTransfer: "Taxa de repasse",
      rentalFee: "Taxa de Locação",
      minimumFee: "Taxa mínima",
      technicalHourFee: "Taxa da hora técnica",
      serviceFee: "Taxa de serviço",
      discount: "Desconto",
      rechargeRefund: "Estorno de Recarga",
    },
    useRole: {
      eleven: "ELEVEN",
      imply: "IMPLY",
      representative: "REPRESENTANTE",
      technician: "TÉCNICO",
      productionAdmin: "ADMINISTRADOR DE PRODUÇÃO",
      operationAdmin: "ADMINISTRADOR DE OPERAÇÃO",
      attendant: "ATENDENTE",
      other: "-",
    },
    documentType: {
      cpf: "CPF",
      passport: "Passaporte",
      foreign: "Estrangeiro",
      other: "-",
    },
  },
};

export default pt;
