<template>
  <v-app id="app">
    <router-view />
    <FloatingAlert />
  </v-app>
</template>

<script>
import FloatingAlert from '@/components/FloatingAlert.vue';
import router from '@/router';
import { useAdminStore } from '@/store/modules/admin';
import { useAlertaStore } from '@/store/modules/alertas';

export default {
  name: 'App',
  components: {
    FloatingAlert,
  },
  updated() {
    const { checkIsLogado, usuarioLogado } = useAdminStore();
    const { info } = useAlertaStore();

    if (this.$route.name && ['PageConsumidorRecuperarAcesso'].includes(this.$route.name)) {
      return;
    }

    checkIsLogado(
      () => {
        if (this.$route.name && ['LoginAdmin', 'Login'].includes(this.$route.name)) {

          if (['operador'].includes(usuarioLogado.role)) {
            return this.$router.push({ name: 'ProdutorHome' });
          }
          this.$router.push({ name: 'ListaEventos' });
        }
      },
      () => {
        if (!['LoginAdmin', 'CardapioTemplate'].includes(router.currentRoute.name)) {
          info(this.$t('form.info.login'));
          if (!this.$route.name || ['LoginAdmin', 'Login'].includes(this.$route.name)) {
            return;
          }

          this.$router.push(`/admin/login`);
        }
      }
    );
  },
};
</script>

<style lang="scss">
@import '@/styles/default';

@font-face {
  font-family: 'Archivo';
  src: url('./assets/font/Archivo.ttf');
}

* {
  print-color-adjust: exact;
  font-family: 'Archivo', sans-serif !important;
}

html,
body {
  overflow-y: auto;
  overflow-anchor: touch; /* Adiciona scrolling nativo no iOS */
}

#app {
  background-color: $bg-color !important;
  height: 100svh !important;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 7px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.35);
}
</style>
