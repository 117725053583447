import Vue from "vue";
import VueI18n from "vue-i18n";

import pt from "@/locales/pt";
import en from "@/locales/en";
import es from "@/locales/es";

Vue.use(VueI18n);

const messages = {
  pt: pt,
  en: en,
  es: es,
};

const savedLocale = localStorage.getItem("locale") || navigator.language.split('-')[0];

const i18n = new VueI18n({
  locale: savedLocale,
  fallbackLocale: 'pt',
  messages,
});

export default i18n;
